<template>
  <b-card title="Lista de regras">
    <b-table
      v-if="searchedGroups"
      :items="searchedGroups"
      :fields="fields"
      striped
      responsive
      class="data-table"
      :key="k"
    >
      <template #cell(actions)="data">
        <feather-icon
          v-if="!data.item.selected"
          icon="PlusIcon"
          class="ml-2 b-fon text-primary pointer"
          @click="addRuleToGroup(data.item)"
        />
        <feather-icon
          v-else
          icon="MinusIcon"
          class="ml-2 b-fon text-danger pointer"
          @click="removeRuleToGroup(data.item)"
        />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'

import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _
  },
  props:['searchedGroups', 'contextActions', 'addInfo', 'customFields'],
  data() {
    return {
      fields: ['id', 'name','actions'],
      k:0,
    }
  },
  apollo:{
    
  },
  watch:{
  
  },
  methods:{
    addRuleToGroup(item){
      this.marker = true;
      this.idToMark = item.id;
      _.each(this.searchedGroups, this.markItem);
      this.$emit('addRuleToGroup', item.id)
    },
    removeRuleToGroup(item){
      this.marker = false;
      this.idToMark = item.id;
      _.each(this.searchedGroups, this.markItem, item.id);
      this.$emit('removeRuleToGroup', item.id)
    },
    markItem(e,l,b){
      if(e.id == this.idToMark){
        e.selected = this.marker;
        this.k++;
      }
    }

  },
  created(){
   
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  .b-fon{
    width: 25px;
    height: 25px;
  }
</style>