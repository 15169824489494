<template>
  <div>

    <div
      v-if="toExport.length==0"
      style="min-height: 100px"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />

      </div>

      Espere demora unos minutos

    </div>

    <b-card
      v-if="toExport.length>0"
      id="cardo"
      title="lista de clientes Cargada! clique no disquete quando ele aparecer"
    >
      {{ feedtex }}
      <div id="export-button">
        <json-excel
          v-if="toExport.length>0"
          :data="toExport"
          :before-generate="labelin"
        >
          <feather-icon
            icon="SaveIcon"
            size="40"
            class="ml-2 text-success pointer"
          />
        </json-excel>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge, BCardText, BLink, BLoading,
} from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'

import gql from 'graphql-tag'
import { _ } from 'vue-underscore'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
    BLoading,
  },
  props: ['searchedUsers', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      fields: ['id'],
      // fields: ['id', {key:'nickname', label: 'Nickname',formatter: 'cellDecoupe'},{key:'fullname', label: 'Nome',formatter: 'cellDecoupe'},{key:'created_at', label: 'Registrado em', formatter:'dateFormatter'},  {key:'invitecode', label: 'Cód Convite',formatter: 'cellDecoupe'}, { key: 'status', label: 'Status' }, { key: 'actions', label: 'Ações' }],
      status: [{
        ACTIVATED: 'Ativado', ACTIVED: 'Ativado', 2: 'Verificado', 3: 'Bloqueado', PENDING_VALIDATION: 'AG. VALIDAÇÃO', 5: 'Applied',
      },
      {
        ACTIVATED: 'light-primary', ACTIVED: 'light-primary', 2: 'light-success', 3: 'light-danger', PENDING_VALIDATION: 'light-warning', 5: 'light-info',
      }],
      users: null,
      queriedUsers: ['1', '9'],
      movements: null,
      userLastMovements: [],
      selectedUserLastWithDraw: [],
      selectedUserWalletId: null,
      wallets: [],
      toExport: [],
      user_ids: [],
      uDocs: null,
      feedtex: '',
    }
  },
  apollo: {

  },
  watch: {
    user_ids() {
      this.makeExport()
      // _.each(this.searchedUsers, this.formatSave);
    },
    // users: function(){
    //   let obj = this.users.map(u => u.id)
    //   this.queriedUsers = obj
    // }
  },
  created() {
    this.user_ids = this.searchedUsers.map(obj => obj.id)
  },
  methods: {
    labelin() {
      this.feedtex = 'Aguarde, o download iniciará automaticamente...'
    },
    makeExport() {
      this.$apollo.query({
        query: gql`query($user_ids:[ID!],$types:[String]){
         user_documents_by_group(user_ids:$user_ids,types:$types){
            user_id
            type
            value
          }
      }`,
        client: 'identityClient',
        variables: {
          user_ids: this.user_ids,
          types: ['NICKNAME', 'FULLNAME', 'GENDER', 'BIRTHDATE', 'EMAIL', 'CLICKID', 'INVITECODE'],
        },
      }).then(d => {
        console.log('user_documents_by_group', d.data.user_documents_by_group)
        this.uDocs = d.data.user_documents_by_group
        _.each(this.searchedUsers, this.formatSave)
      })
    },
    formatSave(e) {
      // console.log("formatSave", e);

      const userDocs = this.uDocs.filter(d => d.user_id == e.id)

      const toSave = {
        id: e.id,
        phone: e.username,
        status: e.status,
        register: e.created_at,
        invite_code: userDocs && userDocs.filter(d => d.type == 'INVITECODE')[0]?.value,
        click_id: userDocs && userDocs.filter(d => d.type == 'CLICKID')[0]?.value,
        birthdate: userDocs && userDocs.filter(d => d.type == 'BIRTHDATE')[0]?.value,
        gender: userDocs && userDocs.filter(d => d.type == 'GENDER')[0]?.value,
        nickname: userDocs && userDocs.filter(d => d.type == 'NICKNAME')[0]?.value,
        fullname: userDocs && userDocs.filter(d => d.type == 'FULLNAME')[0]?.value,
        email: userDocs && userDocs.filter(d => d.type == 'EMAIL')[0]?.value,

      }
      this.toExport.push(toSave)
      // console.log("toExport", this.toExport);
    },
    cellDecoupe(value, key, item) {
      const obj = item.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },
    ballancer(value, key, item) {
      const obj = this.wallets.data.filter(w => w.user_id == item.id)
      return obj.length > 0 ? obj[0].balance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '-'
    },
    movementRetrieve(userData) {
      const wallet = this.wallets.data.filter(w => w.user_id == userData.id && w.wallet_type_id == 1)
      if (wallet.length > 0) {
        this.selectedUserWalletId = wallet[0].id
      } else {
        this.selectedUserWalletId = null
      }
    },
    retrieveLastMovement(data, movementType) {
      const realWallet = _.where(this.wallets.data, { user_id: data.item.id, wallet_type_id: 1 })
      if (this.userLastMovements.length > 0) {
        const moves = _.pluck(this.userLastMovements, 'data')
        const movement = moves[0].filter(w => w.wallet_id == realWallet[0].id && w.movement_type_id == movementType).reverse()
        if (movement.length > 0) return this.$datter.convert((movement[0].created_at))
      }
      return 'Sem informação'
    },
    checkContains(index) {
      return _.contains(this.contextActions, index)
    },
    relationName(value, key, item) {
      const typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if (typeVerifier.length > 0) {
        return typeVerifier[0].relation_type.code
      }
      return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].relation_type.code
    },
    removeRelation(relationId) {
      this.$emit('removeRelation', relationId)
    },
    addRelation(user) {
      this.$emit('addRelation', user)
    },
    relationId(item) {
      const typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if (typeVerifier.length > 0) {
        return typeVerifier[0].id
      }
      return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].id
    },
    dateFormatter(value) {
      return this.$datter.convert(value)
    },
    enc(id) {
      return btoa(id)
    },
  },
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-change-name___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
</style>
