<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Crie um grupo de clientes 🔍">
        <b-card-text>Preencha o formulário para gerar um novo grupo</b-card-text>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label for="group-name">Digite o nome do grupo</label>
              <b-form-input
                v-model="group.name"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label for="group-type">Selecione o tipo de grupo</label>
              <b-form-checkbox
                v-model="group.type"
                switch
                name="group-type"
                value="DYNAMIC"
                :unchecked-value="'static'"
                class="mt-1"
                size="md"
              >
                Dinamico
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label for="group-type">Prazo de permanência</label>
              <b-form-select
                v-model="group.turn_over"
                size="lg"
              >
                <b-form-select-option
                  value=""
                  disabled
                >
                  Selecione
                </b-form-select-option>
                <b-form-select-option :value="'DAILY'">
                  Dia
                </b-form-select-option>
                <b-form-select-option :value="'MONTHLY'">
                  Mês
                </b-form-select-option>
                <b-form-select-option :value="'YEARLY'">
                  Ano
                </b-form-select-option>
                <b-form-select-option :value="'INDETERMINATED'">
                  Indeterminada
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              class="act-button mt-2"
              variant="gradient-primary"
              @click="saveGroup"
            >
              Alterar grupo
            </b-button>
          </b-col>

        </b-row>
        <alert-toast
          ref="warning-toast"
          :message="this.warningMessage"
          :variant="this.messageVariant"
        />

      </b-card>
      <b-card
        v-if="group.type == 'DYNAMIC'"
        title="Regras automáticas do grupo"
      >
        <b-col>
          <general-user-group-rules-list
            :searched-groups="all_active_user_group_rules"
            :context-actions="'addToQuery'"
            @removeRuleToGroup="removeRule"
            @addRuleToGroup="addRule"
          />
        </b-col>
      </b-card>
      <b-card title="Usuários no grupo">
        <b-col>
          <simple-clients-list
            :searched-users="group.users"
          />
        </b-col>
      </b-card>
      <b-card title="Promoções no grupo">
        <b-col>
          <general-promotions-list
            :add-info="group.promotions"
            :searched-promos="group.promotions"
            :context-actions="'managePromotionInsideGroup'"
            @remove-promotion-group="removePromotionGroup"
          />
        </b-col>
      </b-card>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BRow, BButton, BCardText, BCol, BLink, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import AlertToast from '@/components/misc/AlertToast.vue'
import GeneralUserGroupRulesList from '@/components/groups/GeneralUserGroupRulesList.vue'
import SimpleClientsList from '@/components/clients/SimpleClientsList.vue'
import GeneralPromotionsList from '@/components/promotions/GeneralPromotionsList.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    underscore: _,
    BCol,
    AlertToast,
    LogoLoaderAnimation,
    BFormGroup,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BFormSelectOption,
    GeneralUserGroupRulesList,
    SimpleClientsList,
    GeneralPromotionsList,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      groupRules: [],
      warningMessage: null,
      messageVariant: null,
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  apollo: {
    user_group_with_details: {
      query: gql`query($group_id:ID!){
        user_group_with_details(id:$group_id){
          id,
          name,
          status,
          type,
          turn_over,
          promotions{
            id,
            name,
            title,
            wallet_type_id,
            value,
            deposit_related,
          }
          users{
            id,
            username,
            status,
            created_at,
            
          }
        }
      }`,
      client: 'identityClient',
      variables() {
        return {
          group_id: this.$route.params.groupId,
        }
      },
    },
  },
  watch: {
    user_group_with_details() {
      this.group = this.user_group_with_details
    },
  },
  created() {
    // this.searchUser()
  },
  methods: {
    addRule(id) {
      this.groupRules.push(id)
    },
    refresh_promotions() {
      console.log('refresh')
      this.$apollo.query({
        query: gql`query($group_id:ID!){
        user_group_with_details(id:$group_id){
          id,
          name,
          status,
          type,
          turn_over,
          promotions{
            id,
            name,
            title,
            wallet_type_id,
            value,
            deposit_related,
          }
          users{
            id,
            username,
            status,
            created_at,
            # documents{
            #   id,
            #   type,
            #   value
            # }
          }
        }
      }`,
        client: 'identityClient',
        variables: {
          group_id: this.group.id,
        },
      }).then(d => {
        this.group = d.data.user_group_with_details
      })
    },
    removePromotionGroup(p) {
      this.$apollo.mutate({
        mutation: gql`mutation removePromoGroupRelation($promotion_id: ID, $group_id: ID){
          removePromoGroupRelation(promotion_id: $promotion_id, group_id: $group_id){
            id
          }
        }`,
        variables: {
          promotion_id: p.g_id,
          group_id: this.group.id,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'promocao removido com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        // this.refresh_promotions()
        location.reload()
      }).catch(error => {
        console.error(error)
      })
    },
    removeRule(id) {
      this.groupRules = this.groupRules.filter(r => r != id)
    },
    saveGroup() {
      if (this.group.name == null || this.group.turn_over == null) {
        this.warningMessage = 'Preencha todos os dados!'
        this.messageVariant = 'warning'
        this.$refs['warning-toast'].showAlert()
      } else if (this.group.type == 'DYNAMIC' && this.groupRules.length < 1) {
        this.warningMessage = 'Grupo do tipo dinâmico precisa de ao menos uma regra selecionada!'
        this.messageVariant = 'warning'
        this.$refs['warning-toast'].showAlert()
      } else {
        this.executeGroupSave()
      }
    },
    executeGroupSave() {
      this.$apollo.mutate({
        mutation: gql`mutation($group_id:ID,$group_name: String,$group_type: String,$group_turn_over: String){
          updateUserGroup(
            id:$group_id,
            name : $group_name,
            type : $group_type,
            status : "ACTIVE",
            turn_over : $group_turn_over,
            last_change_by:"Agent ag"
          ){
            id
          }
        }`,
        variables: {
          group_id: this.group.id,
          group_name: this.group.name,
          group_type: this.group.status,
          group_turn_over: this.group.turn_over,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'Grupo adicionado com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        if (this.group.type == 'DYNAMIC') {
          this.savedGroupId = data.data.createUserGroup.id
          this.saveGroupRules()
        }
      }).catch(error => {
        console.error(error)
      })
    },
    saveGroupRules() {
      _.each(this.groupRules, this.saveGroupRule)
    },
    saveGroupRule(e) {
      this.tmpRule = e
      this.$apollo.mutate({
        mutation: gql`mutation($group_id: ID,$rule_id: ID){
          createUserGroupRulesRelationship(
            user_group_id : $group_id,
            user_group_rule_id : $rule_id,
          ){
            id
          }
        }`,
        variables: {
          group_id: this.savedGroupId,
          rule_id: this.tmpRule,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'Regra adicionada com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>

<style>

</style>
